<template>
  <v-row class="confirmation__invoicing-method">
    <v-col
      class="confirmation__invoicing-method__invoicing-method--wrapper px-0"
      cols="12"
    >
      <cw-select
        id="invoicingMethod"
        v-model="form.invoicingMethod"
        :class="{ 'busy': $wait.waiting('invoicingMethod') }"
        :items="invoicingMethods"
        :hint="invoicingMethodHint"
        :label="invoicingMethodLabel"
        rules="required"
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'CwConfirmationInvoicingMethod',

  components: {
    'cw-select': () => import('@shared/components/inputs/CwSelect'),
  },

  props: {
    form: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    ...mapGetters({
      initialData: 'application/getInitialData',
      offer: 'application/getSelectedOffer',
      product: 'application/getProduct',
    }),

    invoicingMethods() {
      return [
        {
          text: this.$gettext('Email'),
          value: 'email',
        }, {
          text: this.$gettext('Paper invoice'),
          value: 'post',
        },
      ];
    },

    /**
     * Gettext translations
     */
    invoicingMethodHint: vm => vm.$gettext('Please select a Invoicing method'),
    invoicingMethodLabel: vm => vm.$gettext('Invoicing Method'),
  },

  async mounted() {
    this.setInvoicingMethods();
  },

  methods: {
    ...mapActions({
      setData: 'application/setData',
    }),

    async setInvoicingMethods() {
      if (this.form.invoicingMethod) return;

      this.form.invoicingMethod = this.invoicingMethods[0].value;

      if (!this.initialData.isSessionIdentified) return;

      await this.setData({ invoicingMethod: this.form.invoicingMethod });
    },
  },
};
</script>
